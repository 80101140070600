import { h } from 'preact'
import { useState, useEffect } from 'preact/hooks'
import { formatShowingTime } from './helper'

const App = ({ baseUrl, token, projectId, enableSignup, theme }) => {
  const [message, setMessage] = useState('-')

  useEffect(() => {
    setMessage('Hämtar visning')

    const headers = {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    }

    fetch(`${baseUrl}/api/v1/projects/${projectId}/showings/next`, { headers })
      .then((res) => res.json())
      .then((data) => {
        if (data?.message) {
          throw new Error(data.message)
        }
        setMessage(formatShowingTime(data))
      })
      .catch((error) => {
        setMessage(error.message)
      })
  }, [baseUrl, token, projectId])

  return message
}

export default App
