export const formatShowingTime = (showing) => {
  if (showing?.startAt) {
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      weekday: "long",
      hour: "numeric",
      minute: "numeric"
    }
    return new Date(showing.startAt).toLocaleString('sv-SE', options)
  } else {
    return 'Ingen kommande visning'
  }
}
