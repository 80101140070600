import { h, render } from 'preact'
import App from './App'

const { currentScript } = document

// Required configs
const baseUrl = currentScript.getAttribute('baseUrl')
if (!baseUrl) {
  throw Error('Rebla list config "baseUrl" is missing')
}
const token = currentScript.getAttribute('token')
if (!token) {
  throw Error('Rebla list config "token" is missing')
}
const projectId = currentScript.getAttribute('projectId')
if (!projectId) {
  throw Error('Rebla list config "projectId" is missing')
}

// DOM nodes to mount on
const roots = document.getElementsByClassName('rebla-showing')
if (!roots || roots.length <= 0) {
  throw Error('Rebla showing element "rebla-showing" is missing')
}

 Array.prototype.forEach.call(roots, (root, i) => {
  render(
    <App
      key={i}
      token={token}
      baseUrl={baseUrl}
      projectId={projectId}
    />,
    root
  )
})
